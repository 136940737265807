// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-competition-single-js": () => import("./../../../src/templates/competition-single.js" /* webpackChunkName: "component---src-templates-competition-single-js" */),
  "component---src-templates-competitions-archive-js": () => import("./../../../src/templates/competitions-archive.js" /* webpackChunkName: "component---src-templates-competitions-archive-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-event-single-js": () => import("./../../../src/templates/event-single.js" /* webpackChunkName: "component---src-templates-event-single-js" */),
  "component---src-templates-events-archive-js": () => import("./../../../src/templates/events-archive.js" /* webpackChunkName: "component---src-templates-events-archive-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */)
}

